<template>
  <vue-pure-lightbox class="lightbox">  
    <section class="payment has-text-centered">
        <div class="container">
            <table class="reg-form has-text-centered">
            <tr>
                <td class="form-header has-text-left">
                    <p class="org-description is-size-7"></p>
                </td>
            </tr>
            <tr>
                <td class="form-content">
                  <p class="org-description is-size-5">Thanks for choosing a paid plan!</p>
                  <br>
                  <img class="center-image" v-bind:src="require('../assets/smiley-lock.svg')" width="100px">
                  <br>
                  <br>
                  <p class="small-print">Click the button below to be redirected to our payment processing partner, "Stripe" so that you can setup your monthly recurring payments. You will be brought back here automatically afterwards so we'll see you back here in just a minute!</p>

                  <stripe-checkout
                    ref="checkoutRef"
                    :customerEmail="email"
                    :pk="publishableKey"
                    :items="items"
                    :successUrl="successUrl"
                    :cancelUrl="cancelUrl"
                  >
                    <template slot="checkout-button">
                      <button class="button submit is-primary is-outlined" @click="checkout">Let's Go!</button>
                    </template>
                  </stripe-checkout>
                </td>
            </tr>
            <tr>
              <td class="form-footer">
                <br>
                <p class="org-description is-size-7 back-text">I have choosen the wrong plan! Let me change my plan!</p>
                <router-link :to='{ path: "/change/0/" + this.token }'><button class="button submit is-primary is-outlined back-btn">change</button></router-link>
                <br>
              </td>
            </tr>
            </table>
        </div>
    </section>
  </vue-pure-lightbox>
</template>

<script>
import { StripeCheckout } from 'vue-stripe-checkout';
export default {
  props: {
    type: {
      type: String,
      default: "0"
    },
    pid: {
      type: String,
      default: "1"
    },
    token: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    }
  },
  components: {
    StripeCheckout
  },
  data() {
    return {
      loading: false,
      publishableKey: process.env.VUE_APP_PUBLISHABLE_KEY,
      items: [
        {
          quantity: 1,
          plan: this.getplan(this.pid),
        },
      ],
    }
  },
  computed: {
    successUrl() {
      if (this.type == 0) {
        return process.env.VUE_APP_WEB + "/signupdone/1/"+this.token;
      } else if (this.type == 1) {
        return process.env.VUE_APP_WEB + "/dashboard/profile/00/"+this.token;
      } else {
        return process.env.VUE_APP_WEB + "/error";
      }
    },
    cancelUrl() {
      if (this.type == 0) {
        return process.env.VUE_APP_WEB + "/signupdone/2/" + this.token;
      } else if (this.type == 1) {
        return process.env.VUE_APP_WEB + "/dashboard/redirect/profile/failed";
      } else {
        return process.env.VUE_APP_WEB + "/error";
      }
    },
  },
  methods: {
    getplan(p) {
      console.log(p);
      if (p === "1") {
        console.log("standard:" + process.env.VUE_APP_STANDARD);
        return process.env.VUE_APP_STANDARD;
      }
      if (p === "2") {
        console.log("pro:" + process.env.VUE_APP_PRO);
        return process.env.VUE_APP_PRO;
      }
    },
    checkout() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<style lang="scss">
    $main-color: #aac734;
    
    .field-table {
        margin-bottom: 10px;
    }
    .field-table th,td {
        padding-left: 5%;
        padding-right: 5%;
    }
    .email-table {
        margin-left: 8px;
    }
    .plan {
        width: 35%;
        padding-right: 15px;
    }
    .plan-select {
        width: 100%;
        padding-right: 0px;
    }
    .last-ft {
        margin-bottom: 20px;
    }
    .payment {
        padding-top: 25px;
        padding-bottom: 100px;
        width: 100%;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .disclaimer {
        font-size: 0.78rem;
        font-weight: 400;
    }
    .fa-check {
        color: $main-color;
    }
    .is-success {
        color: $main-color;
    }
    .select {
        color: $main-color;
    }
    .select.is-success select {
        color: $main-color;
        border-color: $main-color;
    }
    .select.is-success select:hover {
        border-color: $main-color;
    }
    .select.is-success:not(:hover)::after {
        border-color: $main-color;
    }
    .select:not(.is-multiple):not(.is-loading):hover::after {
        border-color: $main-color;
    }
    .select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
        box-shadow: rgba(170, 199, 52, 0.25);
    }
    .is-success.input {
        color: $main-color;
        border-color: $main-color;
    }
    .has-text-success {
        color: $main-color;
    }
    .submit {
        margin-top: 20px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        max-width: 600px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px;
        padding-right: 10px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        padding: 30px;
        padding-left: 15%;
        padding-right: 15%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
        max-width: 600px;
    }
    .small-print {
      font-size: 0.80rem;
      padding-bottom: 10px;
    }
    .back-text {
      color: #cccccc;
    }
    .back-btn {
      width: 50px;
      height: 25px;
      margin-top: 5px;
      font-size: 12px;
      color: #4d4d4d;
    }
    .form-footer {
      background-color: #4d4d4d;
      padding-bottom: 20px;
    }
</style>